'use client';

import {useRouter} from 'next/navigation';
import {Feed} from '@delorand/api/helpers/feed';
import {Product} from '@delorand/db/schema/product';
import Carousel from '@delorand/ui/src/carousel';
import {filterNulls, filterUndefined, getVariant} from '@delorand/utils/helper';
import AutoPlay from 'embla-carousel-autoplay';
import {InView} from 'react-intersection-observer';
import useSWRMutation from 'swr/mutation';
import ProductPost from '../../../components/section/feed/product-post';
import PromotionSection from '../../../components/section/feed/promotion';
import SocialPost from '../../../components/section/feed/social-post';
import {ProductPostSkeleton} from '../../../components/skeletons';
import {client} from '../../../server/api';
import {useUser} from '../../../utils/hooks';
import {useModifyCart} from '../../../utils/use-cart';
import {Data} from './page';
import {useFeed} from './use-feed';

const HomeFeed = ({initialFeed, promotionSlides}: Data) => {
  const router = useRouter();

  const [user] = useUser();

  const {
    data: hookData,
    isValidating,
    isLoading,
    setSize,
    size,
    error,
  } = useFeed();

  const data = [initialFeed, ...(hookData || [])];

  const lastData = data?.[data.length - 1]?.data;

  const hasMore = lastData
    ? !(
        lastData.posts.length < 1 &&
        lastData.products.length < 1 &&
        lastData.promotions.length < 1
      )
    : false;

  const allRows = (data?.filter(filterNulls) || [])
    .map(({data: x}) =>
      createSequence(
        x.products.map(data => ({
          type: 'products',
          data,
        })),
        x.posts.map(data => ({
          type: 'posts',
          data,
        })),
        x.promotions.map(data => ({
          type: 'promotions',
          data,
        }))
      )
    )
    .flatMap(x => x);

  const {trigger, isMutating: isAddingToCart} = useModifyCart();

  const addToCart = (product: Product) =>
    trigger({
      add: {
        scs: [],
        items: [
          {
            quantity: 1,
            variantId: getVariant(product.variants).id,
          },
        ],
      },
    });

  const {trigger: view} = useSWRMutation(
    ['product.view'],
    (_, {arg}: {arg: number}) => client.item.view.mutate(arg)
  );

  const {trigger: follow} = useSWRMutation(
    ['store.follow'],
    async (_, {arg}: {arg: {storeId: number}}) =>
      await client.store.follow.mutate({sid: arg.storeId}),
    {
      onSuccess: () => {},
    }
  );

  const {trigger: likeProduct} = useSWRMutation(
    ['product.like'],
    async (_, {arg}: {arg: {productId: number}}) =>
      await client.item.engageLike.mutate({productId: arg.productId}),
    {
      onSuccess: () => {},
    }
  );

  const {trigger: likePost} = useSWRMutation(
    ['post.like'],
    async (_, {arg}: {arg: {postId: number}}) =>
      await client.store.likePost.mutate({id: arg.postId}),
    {
      onSuccess: () => {},
    }
  );

  const RenderPost = ({type, data, index}: DynamicPost & {index: number}) => {
    if (type === 'products')
      return (
        <ProductPost
          key={data.id}
          addToCart={() => addToCart(data)}
          viewProduct={() => {
            view(data.id);
            router.push(`/item/${data.slug}`);
          }}
          product={data}
          index={index}
          onFollow={() =>
            follow({
              storeId: data.storeId,
            })
          }
          onLike={() =>
            likeProduct({
              productId: data.id,
            })
          }
          userId={user?.id}
          isAddingToCart={isAddingToCart}
        />
      );
    if (type === 'posts')
      return (
        <SocialPost
          key={data.id}
          post={data}
          index={index}
          onFollow={() =>
            data.storeId
              ? follow({
                  storeId: data.storeId,
                })
              : {}
          }
          onLike={() => likePost({postId: data.id})}
          userId={user?.id}
        />
      );
    if (type === 'promotions')
      return <PromotionSection key={data.id} promotion={data} index={index} />;
  };

  return (
    <div className="h-full">
      <Carousel
        emblaClassName="embla-promotion"
        slides={promotionSlides}
        imageWidth={500}
        imageHeight={350}
        plugins={[
          AutoPlay({
            delay: 4000,
          }) as any,
        ]}
      />
      {allRows.map((item, index) => (
        <RenderPost {...item} index={index} key={index} />
      ))}

      {!isValidating && !isLoading && hasMore && !error ? (
        <InView
          as="div"
          threshold={0.5}
          onChange={inView => {
            if (inView) {
              setSize(size + 1);
            }
          }}
        />
      ) : hasMore ? (
        <div className="flex flex-col gap-6 pt-4">
          {[1, 2, 3, 4].map(x => (
            <ProductPostSkeleton key={x} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default HomeFeed;

function createSequence(
  a: {
    type: 'products';
    data: Feed['products'][0];
  }[],
  b: {
    type: 'posts';
    data: Feed['posts'][0];
  }[],
  c: {
    type: 'promotions';
    data: Feed['promotions'][0];
  }[]
) {
  let result = [];
  const totalLength = a.length + b.length + c.length;

  let i = 0,
    j = 0,
    k = 0;

  for (let index = 0; index < totalLength; index++) {
    if (
      i < a.length &&
      (result.length === 0 || result?.[result.length - 1]?.type !== 'products')
    ) {
      result.push(a[i]);
      i++;
      continue;
    }
    if (
      j < b.length &&
      (result.length === 0 || result?.[result.length - 1]?.type !== 'posts')
    ) {
      result.push(b[j]);
      j++;
      continue;
    }
    if (
      k < c.length &&
      (result.length === 0 ||
        result?.[result.length - 1]?.type !== 'promotions')
    ) {
      result.push(c[k]);
      k++;
      continue;
    }
    // Handle cases where the next element should be from 'A' or 'B' if 'C' would repeat
    if (i < a.length) {
      result.push(a[i]);
      i++;
    } else if (j < b.length) {
      result.push(b[j]);
      j++;
    }
  }

  return result.filter(filterUndefined);
}

type DynamicPost = ReturnType<typeof createSequence>[0];
